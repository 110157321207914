import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo } from 'react';

import Suspense from '../components/framework/suspense';
import useRequest from '../hooks/use_request';

const default_values = {
	refreshUser: () => {},
	user: {},
};

export const AppContext = createContext(default_values);

export const AppProvider = ({ children }) => {
	return (
		<Suspense tags={{ component: 'AppProvider' }}>
			<Request>{children}</Request>
		</Suspense>
	);
};

const Request = ({ children }) => {
	const { data: user, mutate: refreshUser } = useRequest('user.get');

	useEffect(() => {
		if (user?.data?.user_id) {
			Sentry?.setUser({ id: user.data.user_id });
		}
		if (user?.data?.language_id) {
			Sentry?.setExtra('language_id', user.data.language_id);
		}
	}, [user]);

	const value = useMemo(() => {
		return { refreshUser, user: user?.data };
	}, [refreshUser, user]);

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Request.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
